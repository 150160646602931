@font-face {
	font-family: 'TT Interphases';
	src: url('/assets/TTInterphasesVarRoman.woff2') format('woff2'),
		url('/assets/TTInterphasesVarRoman.woff') format('woff');
	font-style: normal;
	font-weight: 100 900;
}

@font-face {
	font-family: 'TT Interphases';
	src: url('/assets/TTInterphasesVarItalic.woff2') format('woff2'),
		url('/assets/TTInterphasesVarItalic.woff') format('woff');
	font-style: italic;
	font-weight: 100 900;
}

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'TT Interphases', 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}
