
.viewer {
  display: flex;
  --light-border: 1px solid rgba(0,0,0,0.2);
}

/* UTILITY STYLES */
.relative {
  position: relative;
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fill {
  width: 100%;
  height: 100%;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* LOADING STYLES */
.loading-dots {
  display: inline-block;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

/* FRAME STYLES */

.frame-container {
  flex: 1;
  position: relative;
}

.frame-container::after {
  position: absolute;
  top: 5rem;
  bottom: 5rem;
  right: 0;

  content: '';
  border-right: var(--light-border);
}

.frame {
  padding: 10rem;
}

.frame iframe {
  border: none;
}

/* INFO STYLES */
.info-container {
  display: flex;
  align-items: flex-end;
  padding: 10rem 5rem;
}

.info {
  padding: 1rem;
  width: 500px;
  border-bottom: var(--light-border);
}

.info .title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.info .mint {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.info .mint .mobile {
  display: none;
}

.info a {
  display: block;
  color: #000;
  text-decoration: none;
}

.info a:hover {
  font-style: italic;
}

.info .website {
  margin-bottom: 1rem;
}
 
.info .description {
  line-height: 1.4;
  margin-bottom: 2rem;
}

.info .view {
  text-align: right;
}

.modal {
  background-color: #000000aa;
  z-index: 10;
}

.modal-container {
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
}

.modal-button-align {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.modal-close-button {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.info-modal {
  overflow: scroll;
  width: 650px;
  height: 400px
}

@media screen and (max-width: 1279px) {
  .viewer {
    flex-direction: column;
  }

  .frame-container {
    flex: 1;
    position: relative;
  }

  .frame-container::after {
    border-right: none;
    border-bottom: 1px solid rgba(0,0,0, 0.2);
    right: 3rem;
    left: 3rem;
    bottom: 0;
  }

  .frame {
    padding: 6rem;
  }

  .info-container {
    padding: 3rem;
  }
  
  .info {
    width: 100%;
    border-bottom: none;
  } 

  .info .mint {
    font-size: 0.875rem;
  }

  .info .mint .mobile {
    display: block;
  }

  .info .mint .desktop {
    display: none;
  }
}